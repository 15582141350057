<template>
  <div>
    <b-card :class="$store.state.appConfig.layout.skin === 'dark' ? 'dark-card rounded-lg' : 'rounded-lg'">
      <b-row>
        <b-col md="4"></b-col>
        <b-col 
          md="4"
        >
        <center v-if="loadItem">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </center>
            <b-form v-else
              class="chat-app-form"
              @submit.prevent="submit"
            >
            
              <validation-observer
                class="w-100"
                ref="courseForm"
                #default="{invalid}"
              >
                <b-row class="pt-3">
                  <b-col
                    md="12"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      vid="name"
                      rules="required"
                    >
                      <b-form-group
                          label="Course Name *"
                          label-for="name"
                          
                      >
                      
                        <b-input-group
                          class="input-group-merge form-send-message mr-1"
                        >
                          <b-form-input
                            autocomplete="off"
                            v-model="form.name"
                            placeholder="Enter Course Name"
                            id="name"
                            class="custom-field"
                          />
                        </b-input-group>
                        <small class="text-danger float-left">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="12"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      vid="description"
                      rules="required"
                    >
                      <b-form-group
                          label="Course description *"
                          label-for="description"
                          
                      >
                        <b-input-group
                          class="input-group-merge form-send-message mr-1"
                        >
                          <b-form-textarea
                            id="textarea-default"
                            v-model="form.description"
                            placeholder="Enter Course Description"
                            class="custom-field"
                          ></b-form-textarea>
                        </b-input-group>
                        <small class="text-danger float-left">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="12"
                    class="mb-2"
                    v-if="editCoverPhoto || !id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cover"
                      vid="cover"
                      rules="required"
                    >
                      <b-form-group
                          label="Course cover photo"
                          label-for="cover"
                      >
                        <b-form-file
                          id="cover"
                          v-model="form.cover_image"
                          :state="Boolean(form.cover_image)"
                          accept="image/*"
                          class="custom-field"
                        />
                      </b-form-group>
                      <small class="text-danger float-left">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col 
                    md="12"
                    v-if="item && item.cover_image && !editCoverPhoto"
                  >
                    <div 
                      class="d-flex align-items-center mb-1 cursor-pointer"
                      @click="editCoverPhoto = true"
                    >
                      <feather-icon 
                        :tooltip="'none'" 
                        icon="EditIcon" 
                        size="20"
                        class="cursor-pointer text-link mr-1" 
                        v-b-tooltip.hover.top="'Edit Image'"
                      />
                      <small>Edit Cover Image</small>
                    </div>
                    <img :src="form.cover_image" alt="" width="100%">
                  </b-col>
                </b-row>
              </validation-observer>
              <div class="w-100 d-flex justify-content-center">
                <b-button
                  variant="outline-secondary"
                  class="rounded-pill mt-2 mr-1"
                  @click="$router.go(-1)"
                >
                  Cancel
                </b-button>
                <b-button
                  variant="primary"
                  class="rounded-pill mt-2"
                  type="submit"
                >
                  Submit
                </b-button>
              </div>
            </b-form>
        </b-col>
        <b-col md="4"></b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  VBTooltip,
  BCardBody,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BForm,
  BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormTextarea,
    flatPickr,
    required,
    BSpinner
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  computed:{
    ...mapGetters({
      item: "courses/item",
      loadItem: "courses/loadItem",
    }),
    id(){
      return this.$route.params.id ? this.$route.params.id : null
    }
  },
  data() {
    return {
      editCoverPhoto: false,
      form:{
        name: null,
        description: null,
        cover_image: null
      }
    }
  },
  methods:{
    init(){
      if(this.id){
        this.$store.dispatch('courses/get', this.id).then(_=>{
          this.form.name = this.item.name
          this.form.description = this.item.description
          this.form.cover_image = this.item.cover_image
        })
      }else{
        this.form.name = null
        this.form.description = null
        this.form.cover_image = null
      }
    },
    submit(){
      this.$refs.courseForm.validate().then(success => {
        if (success) {
          if(this.id){
            this.form.id = this.id
            const formData = new FormData();
            for (const [key, value] of Object.entries(this.form)) {
              formData.append(key, value);
            }
            const payload = { id: this.id , query: formData }
            this.$store.dispatch( 'courses/put' , payload ).then(res=>{
              this.$router.push({name:'courses'})
            }).catch(error => {
                if (error.response) {
                  this.$swal({
                    icon: 'error',
                    title: `<h4>${error.response.data.error}</h4>`,
                    showConfirmButton: true,
                    confirmButtonColor: '#E84185',
                    allowOutsideClick: true
                  });
                }
            });
          }else{
            const formData = new FormData();
            for (const [key, value] of Object.entries(this.form)) {
              formData.append(key, value);
            }
            const payload = { query: formData }
            this.$store.dispatch( 'courses/put' , payload ).then(res=>{
              this.$router.push({name:'courses'})
            }).catch(error => {
                if (error.response) {
                  this.$swal({
                    icon: 'error',
                    title: `<h4>${error.response.data.error}</h4>`,
                    showConfirmButton: true,
                    confirmButtonColor: '#E84185',
                    allowOutsideClick: true
                  });
                }
            });
          }
        }
      })
    }
  },
  mounted(){
    this.init()
  }
}
</script>

<style lang="scss">
  // @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  // @import '@core/scss/vue/libs/chart-apex.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
