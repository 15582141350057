var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{class:_vm.$store.state.appConfig.layout.skin === 'dark' ? 'dark-card rounded-lg' : 'rounded-lg'},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}}),_c('b-col',{attrs:{"md":"4"}},[(_vm.loadItem)?_c('center',[_c('b-spinner',{attrs:{"variant":"primary","label":"Spinning"}})],1):_c('b-form',{staticClass:"chat-app-form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-observer',{ref:"courseForm",staticClass:"w-100",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',{staticClass:"pt-3"},[_c('b-col',{staticClass:"mb-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Course Name *","label-for":"name"}},[_c('b-input-group',{staticClass:"input-group-merge form-send-message mr-1"},[_c('b-form-input',{staticClass:"custom-field",attrs:{"autocomplete":"off","placeholder":"Enter Course Name","id":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('small',{staticClass:"text-danger float-left"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"description","vid":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Course description *","label-for":"description"}},[_c('b-input-group',{staticClass:"input-group-merge form-send-message mr-1"},[_c('b-form-textarea',{staticClass:"custom-field",attrs:{"id":"textarea-default","placeholder":"Enter Course Description"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('small',{staticClass:"text-danger float-left"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),(_vm.editCoverPhoto || !_vm.id)?_c('b-col',{staticClass:"mb-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"cover","vid":"cover","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Course cover photo","label-for":"cover"}},[_c('b-form-file',{staticClass:"custom-field",attrs:{"id":"cover","state":Boolean(_vm.form.cover_image),"accept":"image/*"},model:{value:(_vm.form.cover_image),callback:function ($$v) {_vm.$set(_vm.form, "cover_image", $$v)},expression:"form.cover_image"}})],1),_c('small',{staticClass:"text-danger float-left"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.item && _vm.item.cover_image && !_vm.editCoverPhoto)?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex align-items-center mb-1 cursor-pointer",on:{"click":function($event){_vm.editCoverPhoto = true}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Edit Image'),expression:"'Edit Image'",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer text-link mr-1",attrs:{"tooltip":'none',"icon":"EditIcon","size":"20"}}),_c('small',[_vm._v("Edit Cover Image")])],1),_c('img',{attrs:{"src":_vm.form.cover_image,"alt":"","width":"100%"}})]):_vm._e()],1)]}}])}),_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('b-button',{staticClass:"rounded-pill mt-2 mr-1",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"rounded-pill mt-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Submit ")])],1)],1)],1),_c('b-col',{attrs:{"md":"4"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }